import React from "react";
import Header from "../../components/Header";
import HeaderNews from "../../components/HeaderNews";
import Footer from '../../components/Footer';
import PanelBal from "../../components/PanelBal";
import PanelWrap from "../../components/PanelWrap";
import api from "../../config/api";
import axios from "axios";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import PlayMatch4 from "./PlayMatch4";
import { Link } from "react-router-dom";
import Timer from "../../components/Timer";

class Match4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            total_room:0,
            room_24:0,
            player_online:0,
            marquee:undefined,

            rooms:[],
            links: [],
            amount:10,
            room_name:"",
            room_amount:0,
            room_id:0,
            
            //Wating Variable
            time: {}, 
            seconds: 600,
            is_start:false,
            is_join:false,
            is_created:false,
            user_id:undefined,
        }

        //Wating Variable
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.JoinRoomPopup = React.createRef();
        this.CreateRoomPopup = React.createRef();
        
    }

    async componentDidMount() {
        let self=this;
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
        }

        let user_id = localStorage.getItem('user');
        if (user_id) {
            let user=JSON.parse(user_id);
            this.setState({ user_id: user.user_id});
            this.setState({ avatar: user.avatar});
        }
        this.rooms(api.match4_list);
        this.getStatistic();
        try {
            var socket = io.connect(api.GameURL);
                socket.on('connect', function (data) {
            });

            socket.on('join', function (data) {
                console.log("join",data);
                if(data.room_id==self.state.room_id){
                    self.setState({is_start:true})
                    self.JoinRoomPopup.current.click();
                    self.CreateRoomPopup.current.click();
                }
            });

            socket.on('start', function (data) {
                
            });

            socket.on('move', function (data) {
                
            });
          
            socket.on('declare_result', function (data) {
               
            });

            socket.on('restart', function (data) {
                
            });
        }
        catch (e) {
        }
    }

    /**
     * Public Statistic
     */
    getStatistic() {
        let that = this;
        axios({
            method: 'get', url: api.statistic,
            data:{},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                that.setState({
                    total_room: ress.data.total_room,
                    room_24: ress.data.room_24,
                    player_online: ress.data.player_online,
                    marquee:ress.data.marquee
                })
            }
        }).catch(function (e) {
        });
    }

    /**
     * Get Report
     */
    async rooms(URL) {
        let that = this;
        await axios({
            method: 'get',
            url: URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                if (ress.data.data) {
                    that.setState({
                        rooms:ress.data.data.data,
                        links: ress.data.data.links,
                    })

                } else {
                    that.setState({
                        data: [],
                        links: [],
                    })
                }
            }
            else {
            }
        }).catch(function (e) {
           console.log(e);
        });
    }

    create(){
        let amount=this.state.amount;
        if(amount==0){
            toast.error('Amount Required')
            return 0;
        }
        else if(isNaN(amount)){
            toast.error('Invalid Amount')
            return 0;
        }
        let that = this;
        this.setState({ isLoading: true });
        axios({
            method: 'post', url: api.match4_create,
            data: {
                amount: this.state.amount,
                room_name:this.state.room_name,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({room_id:ress.data.room.id})
                that.setState({seconds:600,is_created:true})
                that.startTimer();
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }
    
    doCancle(){
        let that = this;
        this.setState({ isLoading: true });
        axios({
            method: 'post', url: api.match4_cancel,
            data: {
                id: this.state.room_id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
               window.location.reload()
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    doJoin(){
        let that = this;
        this.setState({ isLoading: true });
        axios({
            method: 'post', url: api.match4_join,
            data: {
                id: this.state.room_id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
               
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /***
     * Extra
     */
    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
            "h": hours.toString().padStart(2, '0'),
            "m": minutes.toString().padStart(2, '0'),
            "s": seconds.toString().padStart(2, '0')
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds == 0) { 
            if(this.state.is_created==true && this.state.is_join==false && this.state.is_start==false){
                this.doCancle();
            }
            clearInterval(this.timer);
        }
    }

    render() {
        return (
            <>
                <div className="wrapper">
                    <Header />
                    <div id="content">
                        {
                            this.state.is_start===false?
                                <div className="banner d-none d-md-block">
                                    <a target={"_blank"} href="https://card.solswipe.io/">
                                        <img src={api.url+"assets/images/xswipe.png"} className="w-100" alt="" />
                                    </a>
                                </div>
                            :null
                        }
                        
                        <HeaderNews marquee={this.state.marquee}/>
                        {
                            this.state.is_start===false?
                                <div className="banner px-2 d-sm-block d-md-none">
                                    <img src={api.url+"assets/images/bnr_mth_sm.jpg"} className="w-100" alt="" />
                                </div>
                            :null
                        }   
                        {
                            this.state.is_start?
                                <PlayMatch4 room_id={this.state.room_id} is_start={this.state.is_start} user_id={this.state.user_id}/>
                            :
                            <>
                                <div className="container-fluid">
                                    <div className="match">
                                        <ul className="nav nav-pills mb-3" id="pills-tab-game" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pill-game-home-tab" data-bs-toggle="pill" data-bs-target="#pill-game-home" type="button" role="tab" aria-controls="pill-game-home" aria-selected="true">Game Lobby</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pill-game-profile-tab" type="button" role="tab" aria-controls="pill-game-profile" aria-selected="false">🔒 Tournament Channel</button>
                                                {/*<button className="nav-link" id="pill-game-profile-tab" data-bs-toggle="pill" data-bs-target="#pill-game-profile" type="button" role="tab" aria-controls="pill-game-profile" aria-selected="false">🔒 Tournament Channel</button>*/}
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pill-game-home" role="tabpanel" aria-labelledby="pill-game-home-tab">
                                                <div className="row">
                                                    <div className="col-6 head">
                                                        <h1>Available <span>Rooms ({this.state.rooms.length})</span></h1>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <a href="#" className="room" data-bs-toggle="modal" data-bs-target="#createRoomModal1">Create Room</a>
                                                    </div>
                                                    <div className="col" style={{minHeight:300}}>
                                                        {
                                                            this.state.rooms.length>0?
                                                                this.state.rooms.map((d, key) =>
                                                                    <div className="tdata" key={key+100}>
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><img src={d.avatar} alt="" className="avatar-image"/></td>
                                                                                    <td>#Room : {d.name} TICKETS : {d.amount} PIXIU TOKENS</td>
                                                                                    <td className="wait">Waiting For Player 
                                                                                        <span>
                                                                                            <Timer seconds={d.countdown} message="Terminated" start={true}/>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td><a href="#" onClick={()=>this.setState({room_id:d.id,room_amount:d.amount})} data-bs-toggle="modal" data-bs-target="#joinRoomModal">Join</a></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )
                                                                :
                                                                <>No Room(s) Avaialble</>
                                                            }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                        <ul className="pagination pe-0">
                                                            {
                                                                this.state.links.map((item, key) =>
                                                                    <li key={100 + key} className="page-item">
                                                                        <Link
                                                                            className={(item.active) ? "page-link active" : "page-link"}
                                                                            onClick={() => (item.url) ? this.rooms(item.url) : null}
                                                                        >
                                                                            {item.label}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                            </div>
                                            <div className="tab-pane fade" id="pill-game-profile" role="tabpanel" aria-labelledby="pill-game-profile-tab">
                                                <div className="row">
                                                    <div className="col-6 head">
                                                        <h1>Available <span>Rooms (0)</span></h1>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <a href="#" className="room" data-bs-toggle="modal" data-bs-target="#createRoomModal1">Create Room</a>
                                                    </div>
                                                    <div className="col" style={{minHeight:400}}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PanelBal />
                                <PanelWrap />
                            </>
                        }
                        
                    </div>
                    <Footer />
                </div>
                <button ref={this.JoinRoomPopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#joinRoomModal"></button>
                <div className="modal fade" id="joinRoomModal" aria-labelledby="Create Room-2" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content modal-create">
                            <div className="modal-body">
                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <picture>
                                            <img src={(this.state.avatar) ? this.state.avatar : api.url+"assets/images/avatar.png"} alt="" />
                                        </picture>
                                    </div>
                                    <div className="col">
                                        <div className="sm-space">
                                            <h2>{this.state.room_amount}.00 pixiu token will be freeze<br />
                                                <span>From your account when game start.</span></h2>
                                        </div>
                                        {
                                            this.state.isLoading ?
                                                <button type="button" className="btn btn-orange me-2">
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-orange me-2" onClick={()=>this.doJoin()}>Accept</button>
                                        }
                                        <button type="submit" className="btn btn-pink" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button ref={this.CreateRoomPopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#createRoomModal1"></button>
                <div className="modal fade" id="createRoomModal1" aria-labelledby="Create Room" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content modal-create">
                            <div className="modal-body">
                                <div className="row align-items-center">
                                    {
                                        this.state.room_id==0?
                                        <>
                                            <div className="col-md-4">
                                                <picture>
                                                    <img src={(this.state.avatar) ? this.state.avatar : api.url+"assets/images/avatar.png"} alt="" className="avatar-image"/>
                                                </picture>
                                            </div>
                                            <div className="col">
                                                <form>
                                                    <div className="mb-3">
                                                        <input className="form-control" type="text" placeholder="Enter Room Name" onChange={(e)=>this.setState({room_name:e.target.value})} value={this.state.room_name}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <input className="form-control" type="text" placeholder="Enter Entry Tickets Amount, Min 1 Pixiu Token" onChange={(e)=>this.setState({amount:e.target.value})} value={this.state.amount}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option>Auto Close Room when No one Join - 10 Mins</option>
                                                        </select>
                                                    </div>
                                                    {
                                                        this.state.isLoading ?
                                                            <button type="button" className="btn btn-orange me-2">
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-orange me-2" onClick={()=>this.create()}>Create Room</button>
                                                    }
                                                    <button type="button" className="btn btn-pink" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                                </form>
                                            </div>
                                        </>:
                                        <>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <picture>
                                                        <img src={(this.state.avatar) ? this.state.avatar : api.url+"assets/images/avatar.png"} alt="" className="avatar-image"/>
                                                    </picture>
                                                </div>
                                                <div className="col">
                                                    <div className="sm-space">
                                                        <h2><small>Room Created, Waiting For Player To Join.<br />
                                                            <span>Please do not leave this window,<br />
                                                                If not room will be cancel.</span></small></h2>
                                                    </div>
                                                    {
                                                        this.state.isLoading ?
                                                            <button type="button" className="btn btn-orange me-2">
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-pink me-2" aria-label="Close" onClick={()=>this.doCancle()}>{this.state.time.m}:{this.state.time.s} CANCEL</button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Match4;
