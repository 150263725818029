import React from "react";
import api from "../config/api";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";
import { number_format } from "./Util";

class Reward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: undefined,
            is_load: true,
            data: [],
            links: [],
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            setTimeout(() => {
                this.getReward(api.reward_report);
            });
        }
    }

    /**
     * Get Report
     */
    async getReward(URL) {
        let that = this;
        await axios({
            method: 'get',
            url: URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                if (ress.data.data) {
                    that.setState({
                        data: ress.data.data.data,
                        links: ress.data.data.links,
                    })

                } else {
                    that.setState({
                        data: [],
                        links: [],
                    })
                }
            }
            else {
            }

            setTimeout(() => {
                that.setState({ is_load: false });
            });
        }).catch(function (e) {
            setTimeout(() => {
                that.setState({ is_load: false });
            });
        });
    }

    render() {
        return (
            <>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Date/Time</th>
                                <th scope="col">Description</th>
                                <th scope="col">Amounts</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data.length>0?
                                    this.state.data.map((data, key) =>
                                        <tr key={"A" + 100 + key}>
                                            <td>{data.created_at ?? "-"}</td>
                                            <td>{data._di ?? "-"}</td>
                                            <td>{(data.amount > 0) ? "+" : ""}{number_format(data.amount,2,".",",")}</td>
                                        </tr>
                                    )
                                :
                                <tr>
                                    <td colSpan={3}>No Data Avaialble</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="mt-2">
                    <ul className="pagination">
                        {this.state.links.map((item, key) =>
                            <li key={100 + key} className="page-item">
                                <Link
                                    className={(item.active) ? "page-link active" : "page-link"}
                                    onClick={() => (item.url) ? this.getReward(item.url) : null}
                                >
                                    {item.label}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </>
        );
    }
}

export default Reward;