

import React from "react";
import { BrowserRouter, Route, Routes, } from 'react-router-dom';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/index';
import Match4 from './pages/Games/Match4';
import Profile from './pages/Profile';
import RegisterAccount from './pages/Register';

window.process = {};
class App extends React.Component {
    componentDidMount(){
        /*document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });*/
    }
    render() {
        return (
            <>
                <BrowserRouter>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        rtl={false}
                        theme="dark"
                    />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/games/match4" element={<Match4 />} />
                        <Route exact path="/profile" element={<Profile />} />
                        <Route path="/create-account/:sponsor" element={<RegisterAccount/>}/>
                    </Routes>
                </BrowserRouter>
            </>
        );
  }
}
export default App;