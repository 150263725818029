import React from "react";
import './../App.css';
import { NavLink } from "react-router-dom";

class PanelWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isactiveClass: false
        }
    }

    async componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="panel-wrap" style={this.state.isactiveClass ? { marginRight: 0 } : {}}>
                    <button className="btn btn-chat" type="button" onClick={() => this.setState({ isactiveClass: !this.state.isactiveClass })}>🔒 Chat Room</button>
                    <div className="chat">
                        <div className="chat-box">
                            <div className="header">
                                <div className="message-list">
                                    <div className="row">
                                        <div className="col">Chat</div>
                                        {/*<div className="col text-end"><img src="assets/images/icn_psn.png" className="me-1" alt="" /> 100</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="messages">
                            </div>
                            {/*<div className="message-box">
                                <form>
                                    <div className="mb-3">
                                        <input className="form-control" type="text" placeholder="Say something……" />
                                    </div>
                                    <div className="text-end">
                                        <span className="sub">0/200</span> <button type="submit" className="btn btn-subnit ms-1">Send</button>
                                    </div>
                                </form>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PanelWrap;