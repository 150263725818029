import React from "react";
import { NavLink } from "react-router-dom";

class Statistic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <>
                <div className="result">
                    <div className="row">
                        <div className="col">
                            <h4>TOTAL GAMES</h4>
                            <p>{this.props.total_game_played}</p>
                        </div>
                        <div className="col">
                            <h4>GAMES WON</h4>
                            <p>{this.props.total_game_won}</p>
                        </div>
                        <div className="col">
                            <h4>GAMES LOST</h4>
                            <p>{this.props.total_game_lose}</p>
                        </div>
                    </div>
                </div>
                <div className="result">
                    <div className="row">
                        <div className="col">
                            <h4>WIN RATIO</h4>
                            <p>-</p>
                        </div>
                        <div className="col">
                            <h4>BEST/WORST</h4>
                            <p>-/-</p>
                        </div>
                        <div className="col">
                            <h4>TOP UP</h4>
                            <p>0.00 <a href="#" className="float-end ms-3">Topup</a></p>
                        </div>
                    </div>
                </div>
                <div className="result">
                    <div className="row">
                        <div className="col">
                            <h4>REWARDS</h4>
                            <p>0.0000 <a href="#" className="float-end ms-3">Collect</a></p>
                        </div>
                        <div className="col">
                            <h4>FRIEND LISTS</h4>
                            <p>0 Friends</p>
                        </div>
                        <div className="col">
                            <h4>WITHDRAW</h4>
                            <p>0.0</p>
                        </div>
                    </div>
                </div>
                <div className="result">
                    <div className="row">
                        <div className="col-auto">
                            <h4>FORTUNE COOKIES</h4>
                            <p>- <a href="#" className="float-end ms-3">Claims</a></p>
                        </div>
                        <div className="col-auto">
                            <h4>LOTTERY NO.</h4>
                            <p>Coming Soon</p>
                        </div>
                        <div className="col">
                            <p><a href="#">View All Result</a></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Statistic;