import React from "react";
import api from "../../config/api";
import axios from "axios";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import Timer from "../../components/Timer";

class PlayMatch4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            progress:0,
            room:[],
            room_amount:0,
            room_id:props.room_id,
            user:{},
            player:{},
            
            //Wating Variable
            time: {}, 
            seconds: 600,
            is_start:props.is_start,
            is_splash:true,
            user_id:props.user_id,

            board: Array(36).fill(null),
            winner:null,
            mark:'X',
            is_choosed:false,
            is_saved:false,
            is_your_turn:false,
            is_curent_player:undefined,
            is_start_play:false,

            play_time:30,
            last_position:undefined,
            timerUser:false,
            timerPlayer:false,
            
            is_rotate:0,
            choosed_index:null,
            rotate_image:0,
            is_muted:false,
        }

        //Wating Variable
        this.timer = 0;
        this.timeout=null;
        this.yourTurnPopup = React.createRef();
        this.startPopup = React.createRef();
        this.winPopup = React.createRef();
        this.losePopup = React.createRef();
        this.rotatePopup= React.createRef();
        this.drawPopup= React.createRef();

        this.closeYourTurnPopup = React.createRef();
        this.closeStartPopup = React.createRef();
        this.closeWinPopup = React.createRef();
        this.closeLosePopup = React.createRef();
        this.closeRotatePopup = React.createRef();
        this.closeDrawPopup = React.createRef();

        this.audio = new Audio(api.url+"assets/sound/match4_background.mp3");
    }

    async componentDidMount() {
        let self=this;
        let it=setInterval(function(){
            let progress=self.state.progress+1;
            if(progress>100){

            }
            else{
                self.setState({progress:progress});
            }
        },45);

        setTimeout(function(){
            self.setState({is_splash:false});
            let muted = localStorage.getItem('audio_muted');
            if (muted==null || muted==undefined) {
                this.audio.play();
                this.audio.loop=true;
                self.setState({is_muted:false});
            }
            else if (muted==='false') {
                this.audio.play();
                this.audio.loop=true;
                self.setState({is_muted:false});
            }
            else if (muted==='true') {
                self.setState({is_muted:true});
            }
            clearInterval(it);
        },5000)

        let user_id = localStorage.getItem('user');
        if (user_id) {
            let user=JSON.parse(user_id);
            this.setState({ user_id: user.user_id});
        }
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            this.details(auth_token);
        }

        window.addEventListener("beforeunload", (ev) => 
        {  
            if(this.state.winner!=null){
                return true;
            }
            ev.preventDefault();
            this.doCancleOnClose();
            return ev.returnValue = 'Are you sure you want to close?';
        });

        try {
            var socket = io.connect(api.GameURL);
                socket.on('connect', function (data) {
            });

            socket.on('start', function (data) {
                console.log("start",data);
                if(data.room_id==self.state.room_id){
                    if(data.turn_id==self.state.user_id){
                        self.setState({is_choosed:false,is_saved:false,is_your_turn:true});
                        self.yourTurnPopup.current.click();
                        if(data.turn_id==self.state.user.id){
                            self.setState({is_curent_player:1,timerUser:true})
                        }
                        else{
                            self.setState({is_curent_player:2,timerPlayer:true})
                        }
                        setTimeout(function(){
                            self.closeYourTurnPopup.current.click();
                        },3000);

                        clearTimeout(self.timeout);
                        self.timeout=setTimeout(function(){
                            //if Call it 
                            if(self.state.is_your_turn){
                                self.setState({is_your_turn:false});
                                self.storeMove();
                            }
                        },30000);
                    }
                }
            });

            socket.on('turn', function (data) {
                console.log("turn",data);
                if(data.room_id==self.state.room_id){
                    if(data.turn_id==self.state.user_id){
                        self.setState(
                            {
                                choosed_index:null,
                                is_rotate:0,
                                is_choosed:false,
                                last_position:undefined,
                                is_saved:false,
                                is_your_turn:true
                            }
                        );
                        if(data.rotate==null || data.rotate==undefined || data.rotate==0){
                            self.yourTurnPopup.current.click();
                            setTimeout(function(){
                                self.closeYourTurnPopup.current.click();
                            },3000);
                        }
                        else{
                            self.setState({rotate_image:data.rotate})
                            self.rotatePopup.current.click();
                            setTimeout(function(){
                                self.closeRotatePopup.current.click();
                            },3000);

                            setTimeout(function(){
                                self.yourTurnPopup.current.click();
                            },4000);

                            setTimeout(function(){
                                self.closeYourTurnPopup.current.click();
                            },7000);
                        }
                    }
                    if(data.turn_id==self.state.user.id){
                        self.setState({is_curent_player:1,timerUser:true})
                    }
                    else{
                        self.setState({is_curent_player:2,timerPlayer:true})
                    }
                    self.setState({board:data.board});
                    clearTimeout(self.timeout);
                    self.timeout=setTimeout(function(){
                        //if Call it 
                        if(self.state.is_your_turn){
                            self.setState({is_your_turn:false});
                            self.storeMove();
                        }
                    },30000);
                }
            });
          
            socket.on('result', function (data) {
                console.log("result",data);
                if(data.room_id==self.state.room_id){
                    self.setState({
                        board:data.board,
                        winner:data.result
                    });
                    if(data.result=="D"){
                        self.drawPopup.current.click();
                    }
                    else if(self.state.mark==data.result){
                        self.winPopup.current.click();
                        setTimeout(function(){
                            self.winPopup.current.click();
                        },3000);
                    }
                    else{
                        self.losePopup.current.click();
                        setTimeout(function(){
                            self.losePopup.current.click();
                        },3000);
                    }
                }
            });

            socket.on('restart', function (data) {
                console.log("restart",data);
                if(data.room_id==self.state.room_id){
                   //Proceed 
                   window.location.reload();
                }
            });
        }
        catch (e) {
        }
    }
    
    handleClick = (index) => {
        let board=this.state.board;
        if (board[index] === null && !this.state.winner && this.state.last_position==undefined && this.state.is_choosed==false && this.state.is_your_turn==true) {
            const newBoard = [...board];
            newBoard[index] = this.state.mark;
            this.setState({board:newBoard,last_position:index,choosed_index:index});
            clearTimeout(this.timeout);
        }
    }
    
    doUndo(){
        if(this.state.last_position!=undefined){
            let board=this.state.board;
            const newBoard = [...board];
            newBoard[this.state.last_position] = null;
            this.setState({board:newBoard,last_position:undefined,choosed_index:null,is_choosed:false});
        }
        else if(this.state.is_rotate!=null){
            this.setState({is_rotate:0,is_choosed:false});
        }
        else{
            toast.warn("No Action to undo");
        }
    }

    doMuteUnmute(){
        if(this.state.is_muted==false){
            this.setState({is_muted:true})
            localStorage.setItem('audio_muted',true);
            this.audio.pause()
        }
        else{
            this.setState({is_muted:false})
            localStorage.setItem('audio_muted',false);
            this.audio.play();
            this.audio.loop=true;
        }
    }
    saveRotate(id){
        if(this.state.is_rotate==0 && this.state.winner==null && this.state.is_choosed==false && this.state.is_your_turn==true){
            this.setState({is_rotate:id,is_choosed:true})
        }
    }

    renderCell = (index,color) => {
        return (
            <div className="col-4" onClick={() => this.handleClick(index)}>
                <a className={color+" text-center clickable"}>
                    {
                        this.state.board[index]=="X"?
                            <img src={api.url+"assets/images/cross.png"} className="mt-2 game-dice" alt=""/>
                        :
                        null
                    }
                    {
                        this.state.board[index]=="O"?
                            <img src={api.url+"assets/images/zo.png"} className="mt-2 game-dice" alt=""/>
                        :
                        null
                    }
                </a>
            </div>
        );
    }

    details(auth_token){
        let that = this;
        axios({
            method: 'post', url: api.match4_details,
            data: {
                id: this.state.room_id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + auth_token,
            }
        }).then(function (ress) {
            that.setState({ room: ress.data.room })
            that.setState({ room_amount: ress.data.room.amount })
            that.setState({ user: ress.data.room.user })
            that.setState({ player: ress.data.room.player })
            if(that.state.user_id==ress.data.room.user.id){
                that.setState({ mark: 'X'})
                //that.setState({ mark: 'X',is_your_turn:true})
            }
            else{
                that.setState({ mark: 'O'})
            }
        }).catch(function (e) {
        });
    }

    storeMove(){
        if(this.state.is_saved==false && this.state.winner==null){
            let that = this;
            this.setState({isLoading:true});
            axios({
                method: 'post', url: api.match4_store_move,
                data: {
                    position: this.state.choosed_index,
                    room_id:this.state.room_id,
                    rotate:this.state.is_rotate
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Accept-Language': this.state.lang,
                    'Authorization': 'Bearer ' + this.state.auth_token,
                }
            }).then(function (ress) {
                that.setState({isLoading:false});
                if(ress.data.error==200){
                    that.setState({is_saved:true,is_your_turn:false});
                }
            }).catch(function (e) {
                that.setState({isLoading:false});
            });
        }
    }

    doCancleOnClose(){
        let that = this;
        this.setState({ isLoading: true });
        axios({
            method: 'post', url: api.match4_cancel_on_close,
            data: {
                id: this.state.room_id,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
               window.location.reload()
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    render() {
        return (
            <>
                {
                    this.state.is_splash?
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 my-4">
                                    <div className="match_game">
                                        <div className="cover">
                                            <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                <div className="progress-bar" style={{width: this.state.progress+"%"}}></div>
                                                <span>{this.state.progress}%</span>
                                            </div>
                                            <p>
                                                DO NOT CLOSE THE BROWSER WHILE PLAYING.<br/>
                                                CLOSING THE BROWSER WINDOW OR TAB WILL CONSIDER THE MATCH AS FORFEITED<br/>
                                                AND PIXIU TOKENS WILL NOT BE REFUNDED. THE OTHER PARTY WILL BE CONSIDERED THE WINNER.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 my-4">
                                    <div className="match_game">
                                        <div className="frame">
                                            <div className="section">
                                                <div className="row gx-3">
                                                    <div className="col">
                                                        
                                                        <div className="player">
                                                            
                                                            <div className="row align-items-center gx-2">
                                                                <div className="col-2">
                                                                    {
                                                                        this.state.user?
                                                                            <img src={this.state.user.avatar} alt="" className="avatar-image"/>
                                                                            :
                                                                            <img src={api.url+"assets/images/atr_04.png"} alt=""/>
                                                                    }
                                                                </div>
                                                                <div className="col-8">
                                                                    <h5>
                                                                        {
                                                                            this.state.user?
                                                                                this.state.user.name
                                                                                :
                                                                            null
                                                                        }
                                                                    </h5>
                                                                </div>
                                                                <div className="col-2">
                                                                    <img src={api.url+"assets/images/cross.png"} alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 gx-2">
                                                                {
                                                                    this.state.is_curent_player==1?
                                                                    <>
                                                                        <div className="col-10 time">
                                                                            <h5>TURN</h5>
                                                                        </div>
                                                                        <div className="col-2 time">
                                                                            <Timer seconds={30} message="00:00" start={this.state.timerUser}/>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="col-12">
                                                                            <h5>WAITING</h5>
                                                                        </div>
                                                                    </>
                                                                }                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="player">
                                                            <div className="row align-items-center gx-2">
                                                                <div className="col-2">
                                                                    {
                                                                        this.state.player?
                                                                            <img src={this.state.player.avatar} alt="" className="avatar-image"/>
                                                                            :
                                                                            <img src={api.url+"assets/images/atr_07.png"} alt=""/>
                                                                    }
                                                                </div>
                                                                <div className="col-8">
                                                                    <h5>
                                                                        {
                                                                            this.state.player?
                                                                                this.state.player.name
                                                                                :
                                                                            null
                                                                        }
                                                                    </h5>
                                                                </div>
                                                                <div className="col-2">
                                                                    <img src={api.url+"assets/images/zo.png"} alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 gx-2">
                                                                {
                                                                    this.state.is_curent_player==2?
                                                                    <>
                                                                        <div className="col-10 time">
                                                                            <h5>TURN</h5>
                                                                        </div>
                                                                        <div className="col-2 time">
                                                                            <Timer seconds={30} message="00:00" start={this.state.timerPlayer}/>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="col-12">
                                                                            <h5>WAITING</h5>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="square-game">
                                                <div className="row g-2">
                                                    <div className="col-6 position-relative">
                                                        <div className="row g-2">
                                                            {this.renderCell(0,"dice-red")}
                                                            {this.renderCell(1,"dice-red")}
                                                            {this.renderCell(2,"dice-red")}
                                                            
                                                            {this.renderCell(6,"dice-red")}
                                                            {this.renderCell(7,"dice-red")}
                                                            {this.renderCell(8,"dice-red")}

                                                            {this.renderCell(12,"dice-red")}
                                                            {this.renderCell(13,"dice-red")}
                                                            {this.renderCell(14,"dice-red")}
                                                        </div>
                                                        <div className="reload-red leftout">
                                                            {
                                                                this.state.is_rotate==1?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(1)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(1)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                            }
                                                            
                                                            {
                                                                this.state.is_rotate==2?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(2)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(2)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 position-relative">
                                                        <div className="row g-2">
                                                            {this.renderCell(3,"dice-violet")}
                                                            {this.renderCell(4,"dice-violet")}
                                                            {this.renderCell(5,"dice-violet")}

                                                            {this.renderCell(9,"dice-violet")}
                                                            {this.renderCell(10,"dice-violet")}
                                                            {this.renderCell(11,"dice-violet")}
                                                            
                                                            {this.renderCell(15,"dice-violet")}
                                                            {this.renderCell(16,"dice-violet")}
                                                            {this.renderCell(17,"dice-violet")}
                                                            
                                                        </div>
                                                        <div className="reload-violet rightout">
                                                            {
                                                                this.state.is_rotate==3?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(3)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(3)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                            }
                                                            {
                                                                this.state.is_rotate==4?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(4)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(4)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                            }
                                                        </div>
                                                    </div>                  
                                                    <div className="col-6 position-relative">
                                                        <div className="row g-2">
                                                            {this.renderCell(18,"dice-violet")}
                                                            {this.renderCell(19,"dice-violet")}
                                                            {this.renderCell(20,"dice-violet")}

                                                            {this.renderCell(24,"dice-violet")}
                                                            {this.renderCell(25,"dice-violet")}
                                                            {this.renderCell(26,"dice-violet")}
                                                            
                                                            {this.renderCell(30,"dice-violet")}
                                                            {this.renderCell(31,"dice-violet")}
                                                            {this.renderCell(32,"dice-violet")}
                                                        </div>
                                                        <div className="reload-violet leftout">
                                                            {
                                                                this.state.is_rotate==5?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(5)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(5)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                            }
                                                            {
                                                                this.state.is_rotate==6?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(6)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(6)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 position-relative">
                                                        <div className="row g-2">
                                                            {this.renderCell(21,"dice-red")}
                                                            {this.renderCell(22,"dice-red")}
                                                            {this.renderCell(23,"dice-red")}
                                                            
                                                            {this.renderCell(27,"dice-red")}
                                                            {this.renderCell(28,"dice-red")}
                                                            {this.renderCell(29,"dice-red")}

                                                            {this.renderCell(33,"dice-red")}
                                                            {this.renderCell(34,"dice-red")}
                                                            {this.renderCell(35,"dice-red")}

                                                        </div>
                                                        <div className="reload-red rightout">
                                                            {
                                                                this.state.is_rotate==7?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(7)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                                :
                                                                    <a className="refresh" onClick={()=>this.saveRotate(7)}><img src={api.url+"assets/images/refresh.png"} alt=""/></a>
                                                            }
                                                            {
                                                                this.state.is_rotate==8?
                                                                    <a className="refresh active-rotate" onClick={()=>this.saveRotate(8)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                                :
                                                                <a className="refresh" onClick={()=>this.saveRotate(8)}><img src={api.url+"assets/images/refresh_01.png"} alt=""/></a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>              
                                        </div>
                                        <div className="mt-4 mb-3 text-center">
                                            {
                                                this.state.isLoading==true || this.state.is_your_turn==false?
                                                <button type="button" className="btn btn-orange me-2">
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Waiting...
                                                </button>
                                                :
                                                
                                                <a className="btn btn-orange" onClick={()=>this.storeMove()}>Submit Move</a>
                                            }
                                        </div>
                                        <div className="mt-4 mb-3 text-center">
                                            <a className="btn btn-warning btn-sm" onClick={()=>this.doUndo()}>Unselect Box</a>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {
                                                this.state.is_muted==false?
                                                    <a className="refresh" onClick={()=>this.doMuteUnmute()}><img src={api.url+"assets/images/unmute.png"} alt="" style={{height:30}}/></a>
                                                :
                                                    <a className="refresh" onClick={()=>this.doMuteUnmute()}><img src={api.url+"assets/images/mute.png"} alt="" style={{height:30}}/></a>
                                            }
                                                
                                        </div>
                                        <div className="mb-3 info">
                                            <p>
                                                DO NOT CLOSE THE BROWSER WHILE PLAYING.<br/>
                                                CLOSING THE BROWSER WINDOW OR TAB WILL CONSIDER THE MATCH AS FORFEITED<br/>
                                                AND PIXIU TOKENS WILL NOT BE REFUNDED. THE OTHER PARTY WILL BE CONSIDERED THE WINNER.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <button ref={this.yourTurnPopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#yourTurnPopup"></button>
                <button ref={this.closeYourTurnPopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#yourTurnPopup"></button>
                <div className="modal game-modal fade" id="yourTurnPopup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Submt Movie" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered game-modal-content">
                        <img src={api.url+"assets/images/your_turn.png"} alt=""/>
                    </div>
                </div>

                <button ref={this.winPopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#winPopup"></button>
                <button ref={this.closeWinPopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#winPopup"></button>
                <div className="modal game-modal fade" id="winPopup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Submt Movie" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered game-modal-content">
                        <div className="mx-auto text-center game-modal-content">
                            <h1>Congratulations!! You won <br/>{this.state.room_amount}.00 PXT</h1>
                            <img src={api.url+"assets/images/win.png"} alt="" className="game-modal-image"/>
                        </div>    
                    </div>
                </div>

                <button ref={this.losePopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#losePopup"></button>
                <button ref={this.closeLosePopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#losePopup"></button>
                <div className="modal game-modal fade" id="losePopup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Submt Movie" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered game-modal-content">
                        <div className="mx-auto text-center">
                            <img src={api.url+"assets/images/lose.png"} alt="" className="game-modal-image"/>
                        </div>    
                    </div>
                </div>
                
                <button ref={this.startPopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#startPopup"></button>
                <button ref={this.closeStartPopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#startPopup"></button>
                <div className="modal game-modal fade" id="startPopup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Submt Movie" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered game-modal-content">
                        <img src={api.url+"assets/images/starting.png"} alt="" className="game-modal-image"/>
                    </div>
                </div>

                <button ref={this.rotatePopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#rotatePopup"></button>
                <button ref={this.closeRotatePopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#rotatePopup"></button>
                <div className="modal game-modal fade" id="rotatePopup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Submt Movie" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered game-modal-content">
                        <img src={api.url+"assets/images/rotate_"+this.state.rotate_image+".png"} alt="" className="game-modal-image"/>
                    </div>
                </div>

                <button ref={this.drawPopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#drawPopup"></button>
                <button ref={this.closeDrawPopup} type="button" className="btn btn-primary d-none" data-bs-dismiss="modal" data-bs-target="#drawPopup"></button>
                <div className="modal game-modal fade" id="drawPopup" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="Submt Movie" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered game-modal-content">
                        <img src={api.url+"assets/images/draw.png"} alt="" className="game-modal-image"/>
                    </div>
                </div>
            </>
        );
    }
}
export default PlayMatch4;
