import React from "react";
import api from "../config/api";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";

class Friends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_token: undefined,
            is_load: true,
            data: [],
            links: [],
        }
    }

    async componentDidMount() {
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            setTimeout(() => {
                this.getData(api.friend_list);
            });
        }
    }

    /**
     * Get Friend List
     */
    async getData(URL) {
        let that = this;
        await axios({
            method: 'get',
            url: URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                if (ress.data.tree) {
                    that.setState({
                        data: ress.data.tree.data,
                        links: ress.data.tree.links,
                    })

                } else {
                    that.setState({
                        data: [],
                        links: [],
                    })
                }
            }
            else {
            }

            setTimeout(() => {
                that.setState({ is_load: false });
            });
        }).catch(function (e) {
            setTimeout(() => {
                that.setState({ is_load: false });
            });
            if (e.response.status === 401) {
                that.signPopup.current.click();
            }
        });
    }

    render() {
        return (
            <>
                <div className="row gx-2">
                    {
                        this.state.data.length>0?
                            this.state.data.map((data, key) =>
                                <div className="col-4" key={1000 + key}>
                                    <div className="result">
                                        <div className="row gx-1">
                                            <div className="col-4"><img src={(data._avatar) ? data._avatar : "assets/images/atr_01.png"} alt="" /></div>
                                            <div className="col-8">
                                                <h5>{(data.user && data.user.username) ? data.user.username : "-"}</h5>
                                                {/* <p><a href="#">View</a></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                        <>No Friend(s) Avaialble</>
                    }
                </div>
                <div className="mt-2">
                    <ul className="pagination">
                        {this.state.links.map((item, key) =>
                            <li key={100 + key} className="page-item">
                                <Link
                                    className={(item.active) ? "page-link active" : "page-link"}
                                    onClick={() => (item.url) ? this.getData(item.url) : null}
                                >
                                    {item.label}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </>
        );
    }
}
export default Friends;