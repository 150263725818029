import React from "react";
import Header from "../components/Header";
import HeaderNews from "../components/HeaderNews";
import Footer from '../components/Footer';
import PanelBal from '../components/PanelBal';
import PanelWrap from '../components/PanelWrap';

import Slider from "react-slick";
import api from "../config/api";
import { number_format } from "../components/Util";
import axios from "axios";
import { Link } from "react-router-dom";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total_room:0,
            room_24:0,
            player_online:0,
            marquee:undefined,

            credit:[],
            play:[],
        }
    }

    async componentDidMount() {
        let credit=localStorage.getItem('leaderboard_1');
        let play=localStorage.getItem('leaderboard_2');
        if(credit!=null && play!=null){
            this.setState({
                credit: JSON.parse(credit),
                play:  JSON.parse(play)
            })
        }
        this.getStatistic();
        this.getLeaderboard();
        this.playerOnline();
    }

    /**
     * Public Statistic
     */
    getStatistic() {
        let that = this;
        axios({
            method: 'get', url: api.statistic,
            data:{},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                that.setState({
                    total_room: ress.data.total_room,
                    room_24: ress.data.room_24,
                    marquee:ress.data.marquee
                })
            }
        }).catch(function (e) {
        });
    }

    playerOnline() {
        let that = this;
        axios({
            method: 'get', url: api.player_online,
            data:{},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                that.setState({
                    player_online: ress.data.count
                })
            }
        }).catch(function (e) {
        });
    }

    /**
     * Public Leaderboard
     */
     getLeaderboard() {
        let that = this;
        axios({
            method: 'get', url: api.loaderboard,
            data:{},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            localStorage.setItem('leaderboard_1',JSON.stringify(ress.data.credit));
            localStorage.setItem('leaderboard_2',JSON.stringify(ress.data.play));
            that.setState({
                credit: ress.data.credit,
                play: ress.data.play
            })
        }).catch(function (e) {
        });
    }


    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 375,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: false,
                        arrows: false,
                    }
                }
            ]

        };

        return (
            <>
                <div className="wrapper">
                    <Header />
                    <div id="content">
                        <HeaderNews marquee={this.state.marquee}/>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Statistics</h1>
                                </div>
                                <div className="col-md-4">
                                    <div className="hilite">
                                        <p>Total room created (all times)</p>
                                        <h2>{number_format(this.state.total_room,0,".",",")}</h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="hilite">
                                        <p>Total room created (24 h)</p>
                                        <h2>{number_format(this.state.room_24,0,".",",")}</h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="hilite">
                                        <p>Total players online (24h)</p>
                                        <h2>{number_format(this.state.player_online,0,".",",")}</h2>
                                    </div>
                                </div>
                            </div>
                            <div id="carouselHero" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselHero" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="assets/images/fortunecookie.png" className="d-block w-100" alt="" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="assets/images/raffles.png" className="d-block w-100" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <h1>Pixiu <span>Games</span></h1>
                            </div>
                            <div className="pixiu">
                                <Slider {...settings}>
                                    <div className="slider-item">
                                        <Link to="/games/match4">
                                            <img src="assets/images/match4.png" alt="" />
                                        </Link>
                                    </div>
                                    <div className="slider-item">
                                        <img src="assets/images/drakonarena.png" alt="" />
                                    </div>
                                    <div className="slider-item">
                                        <img src="assets/images/drakonbound.png" alt="" />
                                    </div>
                                    <div className="slider-item">
                                        <img src="assets/images/drakonmaze.png" alt="" />
                                    </div>
                                </Slider>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h1>Leaderboards</h1>
                                </div>
                                <div className="col">
                                    <span className="subhead">TOP USERS MOST MATCHES WON ( ALL TIME VOLUME )</span>
                                    <div className="table-responsive mt-4">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>USER</th>
                                                    <th>TOTAL MATCHES WON</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.play.length>0?
                                                        this.state.play.map((d, key) =>
                                                            <tr key={"A" + 400 + key}>
                                                                <td>{key+1}.</td>
                                                                <td className="text-start"><img src={d.avatar} alt="" className="avatar-image"/>{d.name}</td>
                                                                <td>{d.total}</td>
                                                            </tr>
                                                        )
                                                    :
                                                    <tr>
                                                        <td colSpan={3}>No Data Avaialble</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col">
                                    <span className="subhead">TOP USERS HIGHEST AMOUNT PXT OWN (ALL TIME VOLUME)</span>
                                    <div className="table-responsive mt-4">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>Users</th>
                                                    <th>PXT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.credit.length>0?
                                                        this.state.credit.map((d, key) =>
                                                            <tr key={"A" + 100 + key}>
                                                                <td>{key+1}.</td>
                                                                <td className="text-start"><img src={d.avatar} alt="" className="avatar-image"/>{d.name}</td>
                                                                <td>{number_format(d.pxt,2,".",",")}</td>
                                                            </tr>
                                                        )
                                                    :
                                                    <tr>
                                                        <td colSpan={3}>No Data Avaialble</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <PanelBal />
                <PanelWrap />
            </>
        );
    }
}
export default Home;
