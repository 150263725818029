import React from "react";
import './../App.css';
import { NavLink } from "react-router-dom";
import api from "../config/api";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeClass: ""
        }
    }

    async componentDidMount() {

    }

    render() {
        return (
            <>
                <nav id="sidebar" className={this.state.activeClass ? this.state.activeClass : ""}>
                    <div className="sidebar-header">
                        <button type="button" className="btn-close sidebarCollapse" onClick={() => this.setState({ activeClass: "" })}></button>
                        <NavLink to={"/"} >
                            <img src={api.url+"assets/images/logo.png"} alt="" />
                        </NavLink>
                    </div>
                    <h5 className="title"><img src={api.url+"assets/images/control.png"} alt="" /> ALL GAMES</h5>
                    <ul>
                        <li>
                            <NavLink className="nav-link" to="/games/match4">
                                <picture><img src={api.url+"assets/images/icn_match.png"} alt="" /></picture> Match 4
                            </NavLink>
                        </li>
                        <li>
                            <a href="#">
                                <picture><img src={api.url+"assets/images/icn_ship.png"} alt="" /></picture> Viking ship <img src={api.url+"assets/images/lock.png"} className="float-end" alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <picture><img src={api.url+"assets/images/icn_dragon.png"} alt="" /></picture> Dragonbound <img src={api.url+"assets/images/lock.png"} className="float-end" alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <picture><img src={api.url+"assets/images/icn_bomb.png"} alt="" /></picture> Bombsquad <img src={api.url+"assets/images/lock.png"} className="float-end" alt="" />
                            </a>
                        </li>
                    </ul>
                    <h5 className="title"><img src={api.url+"assets/images/card.png"} alt="" /> Vault</h5>
                    <ul>
                        <li>
                            <NavLink href="#">
                                <picture><img src={api.url+"assets/images/icn_trn.png"} alt="" /></picture> My Pixiu <img src={api.url+"assets/images/lock.png"} className="float-end" alt="" />
                            </NavLink>
                        </li>
                    </ul>
                    <h3 className="title"><img src={api.url+"assets/images/cog.png"} alt="" />Resources</h3>
                    <ul className="media">
                        <li><a href="https://twitter.com/pixiuverse" target={"_blank"}>Follow Us on Twitter</a></li>
                        <li><a href="https://discord.gg/solswipe" target={"_blank"}>Join our Community</a></li>
                        <li><a href="https://solswipe.gitbook.io/pixiu-litepaper/" target={"_blank"}>Our decks</a></li>
                    </ul>
                </nav>
                <nav className="navbar fixed-top">
                    <button type="button" className="sidebarCollapse btn btn-link" onClick={() => this.setState({ activeClass: "active" })}>
                        <img src={api.url+"assets/images/icn_toggle.png"} alt="" />
                    </button>
                </nav>
            </>
        );
    }
}

export default Header;