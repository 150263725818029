import React from "react";
import { CHAIN_ID, NODE_URL } from "../config/usdt";
import './../App.css';
import { createSolanaMessage, number_format } from "./Util";
import Web3Modal from "web3modal";
import Web3 from "web3";
import api from "../config/api";
import axios from "axios";
import { toast } from "react-toastify";
import Friends from "./Friends";
import Report from "./Report";
import Reward from "./Rewards";
import Statistic from "./Statistic";
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                97: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}

class PanelBal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isactiveClass: false,
            isLoggedIn: false,
            isLoading: false,
            balance: 0,

            is_connected: false,
            account: null,
            blockchain:undefined,

            name: undefined,
            avatar: undefined,
            reprot_data: [],
            reward_data: [],

            file: undefined,
            step:1,

            total_game_played:"-",
            total_game_won:"-",
            total_game_lose:"-",
        }
        this.signPopup = React.createRef();
    }

    async componentDidMount() {
        let chain = localStorage.getItem('chain');
        this.setState({blockchain:chain})
        if (chain == "ETH") {
            //load ETH
            this.loadETH();
        }
        else if (chain == "BSC") {
            this.loadBSC();
        }
        else if (chain == "SOLANA") {
            this.loadSolana();
        }
        else if (chain == "TRON") {
            //load TRON
        }
        else {
            //load ETH
            //this.loadETH();
        }
    }

    /**
     * Load ETH
     */
    async loadETH() {
        const web3ModalBSC = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        let provider;
        if (web3ModalBSC.cachedProvider) {
            provider = await web3ModalBSC.connect()
        }
        else {
            provider = await web3ModalBSC.connect();
        }
        if (provider) {
            this.provider = provider;
            const web3 = new Web3(this.provider);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ is_connected: true, account: accounts[0] })
                let auth_token = localStorage.getItem('auth_token');
                if (auth_token) {
                    this.setState({ auth_token: auth_token })
                    this.setState({ isLoggedIn: true })
                    this.getBalance(accounts[0], auth_token);
                }
                else {
                    this.getBalance(accounts[0], auth_token);
                }
            }
        }
    }

    /**
     * Load BSC
     */
    async loadBSC() {
        const web3ModalBSC = new Web3Modal({
            network: "binance",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        let provider;
        if (web3ModalBSC.cachedProvider) {
            provider = await web3ModalBSC.connect()
        }
        else {
            provider = await web3ModalBSC.connect();
        }
        if (provider) {
            this.provider = provider;
            const web3 = new Web3(this.provider);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ is_connected: true, account: accounts[0] })
                let auth_token = localStorage.getItem('auth_token');
                if (auth_token) {
                    this.setState({ auth_token: auth_token })
                    this.setState({ isLoggedIn: true })
                    this.getBalance(accounts[0], auth_token);
                }
                else {
                    this.getBalance(accounts[0]);
                }
            }
        }
    }

    /**
     * Load Solana
     */
    async loadSolana() {
        try{
            const phantoWallet = new PhantomWalletAdapter();
            await phantoWallet.connect();
            if (phantoWallet.connected) {
                this.setState({ is_connected: true, account: phantoWallet.publicKey.toString() })
                let auth_token = localStorage.getItem('auth_token');
                if (auth_token) {
                    this.setState({ auth_token: auth_token })
                    this.setState({ isLoggedIn: true })
                    this.getBalance(phantoWallet.publicKey.toString(), auth_token);
                }
                else {
                    this.getBalance(phantoWallet.publicKey.toString());
                }
            }
        }
        catch(e){
            toast.error("Install Phantom Wallet");
        }
    }

    /**
     * Connect ETH
     */
    async doConnectETH() {
        return 0;
        const web3ModalETH = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        const provider = await web3ModalETH.connect();
        if (provider) {
            try {
                this.provider = provider;
                const web3 = new Web3(provider);
                this.web3 = web3;
                let accounts = await web3.eth.getAccounts();
                if (accounts.length) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true,blockchain:"ETH" })
                    this.doSignETH();
                }
            } catch (error) {
                console.log(error);
                if (error.code === 4001) {
                    alert(error.message);
                }
                else {
                    alert("Install Metamask or Other Wallet");
                }
            }
        } else {
            alert("Install Metamask or Other Wallet");
        }
    }

    /**
     * Connect BSC
     */
    async doConnectBSC() {
        return 0;
        const web3ModalBSC = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        const provider = await web3ModalBSC.connect();
        if (provider) {
            try {
                this.provider = provider;
                const web3 = new Web3(provider);
                this.web3 = web3;
                let accounts = await web3.eth.getAccounts();
                if (accounts.length) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true,blockchain:"BSC" })
                    this.doSignBSC();
                }
            } catch (error) {
                if (error.code === 4001) {
                    alert(error.message);
                }
                else {
                    alert("Install Metamask or Other Wallet");
                }
            }
        } else {
            alert("Install Metamask or Other Wallet");
        }
    }

    /**
     * Do Sign ETH
     */
    async doSignETH() {
        let that = this;
        that.setState({isLoading:true})
        const web3ModalETH = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        let provider;
        if (web3ModalETH.cachedProvider) {
            provider = await web3ModalETH.connect()
        }
        else {
            provider = await web3ModalETH.connect();
        }
        if (this.state.is_connected) {
            const web3 = new Web3(provider);
            const string = web3.utils.sha3("THE_PIXIUVERSE_PROJECT");
            const accounts = await web3.eth.getAccounts();
            new Promise((resolve, reject) => {
                web3.eth.personal.sign(string, accounts[0], "test").then(async function (data) {
                    that.login(accounts[0], data,'ETH');
                    localStorage.setItem("chain", "ETH")
                    that.setState({isLoading:false})
                    resolve(true);
                });
            });
        }
        else {
            alert("Connect Wallet");
        }
    }

    /**
     * Do Sign BSC
     */
    async doSignBSC() {
        let that = this;
        that.setState({isLoading:true})
        const web3ModalBSC = new Web3Modal({
            network: "mainnet",
            providerOptions,
            cacheProvider: true,
            theme: "dark"
        });
        let provider;
        if (web3ModalBSC.cachedProvider) {
            provider = await web3ModalBSC.connect()
        }
        else {
            provider = await web3ModalBSC.connect();
        }
        if (this.state.is_connected) {
            const web3 = new Web3(provider);
            const string = web3.utils.sha3("THE_PIXIUVERSE_PROJECT");
            const accounts = await web3.eth.getAccounts();
            new Promise((resolve, reject) => {
                web3.eth.personal.sign(string, accounts[0], "test").then(async function (data) {
                    that.login(accounts[0], data,'BSC');
                    localStorage.setItem("chain", "BSC")
                    that.setState({isLoading:false})
                    resolve(true);
                });
            });
        }
        else {
            alert("Connect Wallet");
        }
    }

    getProvider = () => {
        if ('phantom' in window) {
          const provider = window.phantom?.solana;
          if (provider?.isPhantom) {
            return provider;
          }
        }
        window.open('https://phantom.app/', '_blank');
    }
 
    /**
     * Connect Solana
     */
    async doConnectSolana() {
        let self=this;
        try {
            const provider = this.getProvider();
            try {
                const resp = await provider.connect();
                let publicKey = resp.publicKey.toString();
                this.setState({ account: publicKey.toString() })
                this.setState({ is_connected: true,blockchain:"SOLANA" })
                setTimeout(function(){
                    self.doSignSolana();
                },2000)
            } catch (err) {
                console.log(err)
                toast.error(err.message);
            }
        } catch (error) {
          console.log("User rejected the request." + error);
        }
    }

    async doSignSolana() {
        let that=this;
        try {
            const message = createSolanaMessage(this.state.account, "Sign in with Solana to the pixiuvers.");
            const encodedMessage = new TextEncoder().encode(message);
            const provider = await this.getProvider();
            if(provider){
                /*const signedMessage = await provider.request({
                    method: "signMessage",
                    params: {
                        message: encodedMessage,
                        display: "text",
                    },
                });*/
                const signedMessage=await provider.signMessage(encodedMessage)
                that.login(this.state.account, signedMessage.signature,'SOL');
                localStorage.setItem("chain", "SOLANA")
                that.setState({isLoading:false})
            }
            else{
                toast.error("No Provider found");
            }
        } catch (err) {
            console.log(err)
            toast.error(err.message);
        }
    }

    /**
     * Check Balance for Login
     */
    async getBalance(wallet, auth_token,open_modal=false) {
        let that = this;
        await axios({
            method: 'post', url: api.details,
            data: {
                wallet: wallet,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + auth_token,
            }
        }).then(function (ress) {
            //console.log("Balance",ress.data);
            if (ress.data.error === 200) {
                that.setState({
                    username: ress.data.username,
                    name: ress.data.name,
                    avatar: ress.data.avatar,
                    balance: ress.data.balance,
                    total_game_played:ress.data.total_game_played,
                    total_game_won:ress.data.total_game_won,
                    total_game_lose:ress.data.total_game_lose,
                })
                localStorage.setItem("user",JSON.stringify(ress.data))
                if(open_modal){
                    window.location.reload();
                }
            }
            //Name
            else if (ress.data.error === 202) {
                that.setState({
                    username: ress.data.username,
                    name: ress.data.name,
                    avatar: ress.data.avatar,
                    balance: ress.data.balance,
                    step:2,
                })
                that.signPopup.current.click();
                localStorage.setItem("user",JSON.stringify(ress.data))
            }
            //Refcode
            else if (ress.data.error === 203) {
                that.setState({
                    username: ress.data.username,
                    name: ress.data.name,
                    avatar: ress.data.avatar,
                    balance: ress.data.balance,
                    step:3,
                })
                that.signPopup.current.click();
                localStorage.setItem("user",JSON.stringify(ress.data))
            }
            else {
                that.signPopup.current.click();
            }
        }).catch(function (e) {
            console.log(e);
            if (e.response.status === 401) {
                that.signPopup.current.click();
            }
        });
    }

    /**
     * Login
     */
    async login(wallet, secret,device) {
        let that=this;
        await axios({
            method: 'post', url: api.register,
            data: {
                wallet: wallet,
                secret: secret,
                device_name: device
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(function (ress) {
            localStorage.setItem("auth_token", ress.data);
            /*setTimeout(function () {
                window.location.reload();
            }, 2000)*/
            that.setState({"auth_token":ress.data})
            that.getBalance(wallet, ress.data,true);
        }).catch(function (e) {
            console.log(e);
        });
    }

    /**
     * Edit Name
     */
    async editName() {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.edit_name,
            data: {
                name: this.state.name,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                that.setState({step:3})
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    /**
     * Apply Refcode
     */
    async applyRefcode(id) {
        let that = this;
        this.setState({ isLoading: true });
        await axios({
            method: 'post', url: api.add_member_tree,
            data: {
                sponsor: id==0?this.state.sponsor:"PixiuBeta",
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                window.location.reload();
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    handleSubmit = async e => {
        e.preventDefault();
        //if await is removed, console log will be called before the uploadFile() is executed completely.
        //since the await is added, this will pause here then console log will be called
        let res = await this.uploadFile(this.state.file);
    };

    uploadFile = async file => {
        const formData = new FormData();
        formData.append("avatar", file);
        return await axios.post(api.avatar, formData, {
            headers: {
                "content-type": "multipart/form-data",
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        });
    };

    handleOnChange = async e => {
        this.setState({ file: e.target.files[0] });

        e.preventDefault();
        //if await is removed, console log will be called before the uploadFile() is executed completely.
        //since the await is added, this will pause here then console log will be called
        let res = await this.uploadFile(e.target.files[0]);

        if (res && res.data && res.data.error == 200) {
            toast.success('Avatar Uploaded');
        }
    };

    doLogOff(){
        this.setState({isLoading:true});
        localStorage.clear();
        setTimeout(function(){
            window.location.reload();
        },2000)
    }

    render() {
        return (
            <>
                <div className="panel-bal" style={this.state.isactiveClass ? { marginRight: 0 } : {}}>

                    {
                        this.state.isLoggedIn ?
                            <>
                                <button className="btn btn-balance" type="button" onClick={() => this.setState({ isactiveClass: !this.state.isactiveClass })}>BALANCE: {number_format(this.state.balance, 3, ".", ",")}</button>
                                <div className="bala">
                                    <ul className="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" tabIndex={-1}>PROFILE</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-friend-tab" data-bs-toggle="pill" data-bs-target="#pills-friend" type="button" role="tab" aria-controls="pills-friend" aria-selected="false" tabIndex={-1}>FRIEND LISTS</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report" type="button" role="tab" aria-controls="pills-report" aria-selected="true">REPORTS</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-revard-tab" data-bs-toggle="pill" data-bs-target="#pills-revard" type="button" role="tab" aria-controls="pills-revard" aria-selected="true">REWARDS</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                            <div className="row align-items-center mb-3">
                                                <div className="col-3 position-relative">
                                                    <picture>
                                                        <img src={(this.state.avatar) ? this.state.avatar : api.url+"assets/images/avatar.png"} alt="" className="avatar-image"/>
                                                    </picture>
                                                    <label className="filebutton">
                                                        <a className="position-absolute bottom-0 end-0">
                                                            <img src={api.url+"assets/images/upload.png"} width="20" alt="" />
                                                        </a>
                                                        <span><input type="file" id="myfile" name="myfile" onChange={this.handleOnChange} style={{ display: "none" }} /></span>
                                                    </label>
                                                </div>
                                                <div className="col-6 position-relative">
                                                    <h3>{"Display Name"}<br />
                                                        <span>{this.state.name}</span><br/>
                                                        <span>Refcode : {this.state.username}</span>&nbsp;&nbsp;
                                                        <CopyToClipboard text={this.state.username}
                                                            onCopy={() => toast.success("Copied")}>
                                                            <small className="clickable">Copy </small>
                                                        </CopyToClipboard>
                                                    </h3>
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#changeDisplayName" className="position-absolute top-0 end-0"><img src={api.url+"assets/images/edit.png"} alt="" /></a>
                                                </div>
                                            </div>
                                            <Statistic 
                                                total_game_played={this.state.total_game_played}
                                                total_game_won={this.state.total_game_won}       
                                                total_game_lose={this.state.total_game_lose}
                                            />
                                            {
                                                this.state.isLoading ?
                                                    <button type="button" className="btn btn-orange me-2">
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-orange" onClick={()=>this.doLogOff()}>Disconnect</button>
                                            }

                                            
                                        </div>
                                        <div className="tab-pane fade" id="pills-friend" role="tabpanel" aria-labelledby="pills-friend-tab" tabIndex={0}>
                                            <div className="row align-items-center mb-3">
                                                <div className="col-3 position-relative">
                                                    <picture>
                                                        <img src={(this.state.avatar) ? this.state.avatar : api.url+"assets/images/avatar.png"} alt="" className="avatar-image"/>
                                                    </picture>
                                                </div>
                                                <div className="col-6 position-relative">
                                                    <h3>{this.state.name}<br />
                                                        <span>{this.state.username}</span></h3>
                                                </div>
                                            </div>
                                            <Friends />
                                        </div>
                                        <div className="tab-pane fade" id="pills-report" role="tabpanel" aria-labelledby="pills-report-tab" tabIndex={0}>
                                            <Report />
                                        </div>
                                        <div className="tab-pane fade" id="pills-revard" role="tabpanel" aria-labelledby="pills-revard-tab" tabIndex={0}>
                                            <Reward />
                                        </div>
                                    </div>
                                    <div className="claimer">
                                        <a href="#">Disclaimer</a> <a href="#">Terms and Conditions</a>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <button className="btn btn-balance" type="button" onClick={() => this.setState({ isactiveClass: !this.state.isactiveClass })}>Connect Wallet</button>
                                <div className="bala">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="hilite clickable" onClick={() => this.doConnectETH()}>
                                                <picture><img src={api.url+"assets/images/eth.svg"} alt="" /></picture>
                                                <b className="ms-1">Ethereum</b>
                                                <img src={api.url+"assets/images/lock.png"} className="float-end mCS_img_loaded" alt=""></img>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="hilite clickable" onClick={() => this.doConnectBSC()}>
                                                <picture><img src={api.url+"assets/images/bnb.svg"} alt="" /></picture>
                                                <b className="ms-1">Binance Smart Chain</b>
                                                <img src={api.url+"assets/images/lock.png"} className="float-end mCS_img_loaded" alt=""></img>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="hilite clickable" onClick={() => this.doConnectSolana()}>
                                                <picture><img src={api.url+"assets/images/sol.svg"} alt="" /></picture>
                                                <b className="ms-1">Solana</b>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="hilite clickable">
                                                <picture><img src={api.url+"assets/images/trx.svg"} alt="" /></picture>
                                                <b className="ms-1">Tron</b>
                                                <img src={api.url+"assets/images/lock.png"} className="float-end mCS_img_loaded" alt=""></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>

                <button ref={this.signPopup} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#sign_modal"></button>
                <div className="modal fade" id="sign_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content modal-create">
                            <div className="modal-body text-center">
                                {
                                    this.state.step==1?
                                    <>
                                        <b className="modal-title" id="staticBackdropLabel">{"Sign In to Continue"}</b>
                                        <br />
                                        <small>{this.state.account}</small><br/>
                                        {
                                                this.state.isLoading ?
                                                    <button type="button" className="btn btn-orange me-2">
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                    :
                                                    this.state.blockchain=="ETH"?
                                                        <button type="button" className="btn btn-orange text-dark" onClick={() => this.doSignETH()}>{"Sign In"}</button>
                                                    :
                                                    this.state.blockchain=="BSC"?
                                                        <button type="button" className="btn btn-orange text-dark" onClick={() => this.doSignBSC()}>{"Sign In"}</button>
                                                    :
                                                    this.state.blockchain=="SOLANA"?
                                                        <button type="button" className="btn btn-orange text-dark" onClick={() => this.doSignSolana()}>{"Sign In"}</button>
                                                :null
                                        }           
                                        
                                    </>
                                    :
                                    null
                                }

                                {
                                    this.state.step==2?
                                    <>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-10 mx-auto text-center">
                                                <form>
                                                    <h1>Set Display Name</h1>
                                                    <div className="mb-3">
                                                        <input className="form-control display" type="text" placeholder="Enter Display Name" value={this.state.name ?? ''} onChange={(e) => this.setState({ name: e.target.value })} />
                                                    </div>
                                                    {
                                                        this.state.isLoading ?
                                                            <button type="button" className="btn btn-orange me-2">
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-orange me-2" onClick={() => this.editName()}>Submit</button>
                                                    }
                                                    <button type="button" className="btn btn-pink" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                
                                {
                                    this.state.step==3?
                                    <>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-10 mx-auto text-center">
                                                <form>
                                                    <h1>Apply Refcode</h1>
                                                    <div className="mb-3">
                                                        <input className="form-control display" type="text" placeholder="Enter Refcode" value={this.state.sponsor ?? ''} onChange={(e) => this.setState({ sponsor: e.target.value })} />
                                                    </div>
                                                    {
                                                        this.state.isLoading ?
                                                            <button type="button" className="btn btn-orange me-2">
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-orange me-2" onClick={() => this.applyRefcode(0)}>Submit</button>
                                                    }
                                                    <button type="button" className="btn btn-pink" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.applyRefcode(1)}>Skip</button>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="changeDisplayName" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Create Room-2" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content modal-create">
                            <div className="modal-body">
                            <div className="row align-items-center">
                                    <div className="col-12 col-md-10 mx-auto text-center">
                                        <form>
                                            <h1>Set Display Name</h1>
                                            <div className="mb-3">
                                                <input className="form-control display" type="text" placeholder="Enter Display Name" value={this.state.name ?? ''} onChange={(e) => this.setState({ name: e.target.value })} />
                                            </div>
                                            {
                                                this.state.isLoading ?
                                                    <button type="button" className="btn btn-orange me-2">
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-orange me-2" onClick={() => this.editName()}>Submit</button>
                                            }
                                            <button type="button" className="btn btn-pink" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="terms text-end p-3">
                        <a href="#">Disclaimer</a> <a href="#">Terms and Conditions</a>
                    </div>
                </div>
            </>
        );
    }
}

export default PanelBal;