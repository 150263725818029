import React from "react";
import { NavLink } from "react-router-dom";
import api from "../config/api";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col offset-md-2">
                            <picture>
                                <NavLink to={"/"} >
                                    <img src={api.url+"assets/images/logo_ftr.png"} alt="" />
                                </NavLink>
                            </picture>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;