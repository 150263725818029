import React from "react";

class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {
                h:0,
                m:0,
                s:0
            }, 
            seconds: props.seconds,
            message:props.message,
            start:props.start,
            is_complete:false
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    componentDidMount(){
        if(this.state.start){
            this.startTimer();
        }
    }

    /***
     * Extra
     */
    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours.toString().padStart(2, '0'),
          "m": minutes.toString().padStart(2, '0'),
          "s": seconds.toString().padStart(2, '0')
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
        else{
            this.setState({is_complete:true});
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds == 0) { 
            this.setState({is_complete:true});
            clearInterval(this.timer);
        }
    }

    render() {
        return (
            <span>{this.state.is_complete? this.state.message :this.state.time.m +":"+this.state.time.s}</span>
        );
    }
}
export default Timer;
