import React from "react";
import Header from "../../components/Header";
import HeaderNews from "../../components/HeaderNews";
import Footer from '../../components/Footer';
import PanelBal from "../../components/PanelBal";
import PanelWrap from "../../components/PanelWrap";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            room_join: false,
        }
    }

    async componentDidMount() {
    }

    render() {

        return (
            <>
                <div className="wrapper">
                    <Header />
                    <div id="content">
                        <HeaderNews />
                        <div className="banner px-2 d-sm-block d-md-none">
                            <img src="assets/images/bnr_mth_sm.jpg" className="w-100" alt="" />
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 my-4">
                                    <img src="assets/images/game.jpg" className="w-100" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
                <PanelBal />
                <PanelWrap />
            </>
        );
    }
}
export default Profile;
