import React from "react";
import './../App.css';

class HeaderNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            marquee:props.marquee
        }
    }

    async componentDidMount() { 

    }

    render() {
        return (
            <>
                <div className="header">
                    <marquee>{this.props.marquee}</marquee>
                </div>
            </>
        );
    }
}

export default HeaderNews;